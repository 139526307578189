<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="`Edit Course`" :items="items"/>
      <Snackbar ref="snackbar"/>

      <div>
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <ValidationProvider name="Course Name" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.course_name"
                  :error-messages="errors"
                  label="Course Name"
                  autocomplete="off"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Level" rules="required" v-slot="{ errors }">
                <v-select
                  outlined
                  dense
                  :items="level"
                  item-text="label"
                  item-value="name"
                  v-model="form.level"
                  :error-messages="errors"
                  label="Level"
                  autocomplete="off"
                  color="#F05326">
                </v-select>
              </ValidationProvider>
            </div>

            <div class="mt-7 mb-15 float-right">
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize black--text mr-5"
                @click="$router.push('/course')"
                color="#F5F5F5">
                Cancel
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text"
                @click="save"
                color="#F05326">
                Save
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  data() {
    return {
      category: [],
      level: [],
      items: [
        {
          text: 'List Course',
          disabled: false,
          href: '/course',
        },
        {
          text: 'Edit Course',
          disabled: true,
          href: '/course/create',
        }
      ],
      process: {
        run: false
      },
      form: {
        course_name: "",
        level: ""
      },
    }
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar },
  created(){
    this.fetchDetail()
    this.fetctLevel()
  },
  mounted(){},
  methods:{
    fetctLevel(){
      get(`api/v1/level/list`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.level = res.data.list
        }
      })
    },
    fetchDetail(){
      get(`api/v1/package/detail/${this.$route.params.id}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.form = {
            course_name: res.data.title,
            level: res.data.level
          }
        }
      })
    },
    async save(){
      this.$store.state.process.run = true
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        await put(`api/v1/package/update/${this.$route.params.id}`,{
          data: {
            title: this.form.course_name,
            level: this.form.level
          }
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.$refs.snackbar.open("#4CAF50", `New Course Updated Succesfully`);
            setTimeout(() => {
              this.$store.state.process.run = false
              this.$router.push(`/course`)
            }, 1000)
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Course Added Failed`);
          }
        })
      }else{
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Form not valid`);
      }
    }
  }
}
</script>

<style>

</style>